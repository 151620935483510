import logo from './logo.svg';
import './App.css';
import Screen from "./Screen"

function App() {
  return (
    <Screen />
  );
}

export default App;
