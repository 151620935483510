import React, { Component } from 'react';
import { Card, CardDeck, CardGroup, Col, Container, Jumbotron, Nav, Navbar, Row } from 'react-bootstrap';
function Screen() {
    return (
        <Container>
            <header>
            <Navbar collapseOnSelect expand="lg" >
                <Navbar.Brand href="#home">Red Sea Cleaning Services</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="ml-auto">
                        <Nav.Link href="#intro">Home</Nav.Link>
                        <Nav.Link href="#about">About</Nav.Link>
                        <Nav.Link href="#services">Services</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>
                    <Nav>
                        <Nav.Link className="phone" href="tel:0423969234"><i className="fa fa-phone"></i> 0423 969 234</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            </header>

            <Jumbotron id="intro" className="section-intro">
                <div className="content">
                <h1><span className="title">Red Sea Cleaning Services</span></h1>
                <div className="line-spacer"></div>
                <p><span>Always at your service</span></p>
                <p className="description">
                    <span>
                        Red Sea Cleaning Services has been providing the best services to Hobart and surrounding areas since 2019. Fair prices, 
                        superior quality and exceptional customer service are guaranteed when you work with us.
                    </span>
                </p>
                </div>
            </Jumbotron>
            <section id="about" className="section-about">
                
                    <div className="section-header">
                        <div className="content">
                            <div className="inline-content">
                                <div className="img">
                                    
                                </div>
                                <div className="title">
                                    <h2>
                                        <span>A Bit About Us</span>
                                    </h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="description">
                        Founded in Hobart in 2019, Red Sea Cleaning Services provides professional domestic and business cleaning services in the Hobart area. 
                        Our friendly team will take care of the dirty work and let you focus on what's important.
                        No job too small - from light cleaning to full bond cleans.
                        Call us today!
                    </p>


               
            </section>

            <section id="services" className="section-services">
                
                    <div className="section-header">
                            <h2 className="" >Professional Services</h2>
                            
                            <small>Excellent friendly service and great value for money</small>
                    </div>

                    <CardDeck >
                        <Card>
                            <Card.Img src="/img/services/lease.webp" alt="Lease" />
                            <Card.Body>
                                <p className="card-text">End of Lease Cleaning</p>
                                <small>Cheap and Thorough</small>

                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Img src="/img/services/house.webp" alt="House" />
                            <Card.Body>
                                <p className="card-text">House Cleaning</p>
                                <small>Attention to Detail</small>

                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Img src="/img/services/office.webp" alt="Office" />
                            <Card.Body>
                                <p className="card-text">Office Cleaning</p>
                                <small>Expert Service</small>

                            </Card.Body>
                        </Card>
                    </CardDeck>
            </section>

            <section className="list-of-services">

                    <h2 className="section-heading">List of services</h2>

                    <div>
                        <div className="row services-list equal">
                            <div className="col-lg-6">
                                <h4>Domestice Services</h4>
                                <p>Sanitising surfaces, dusting, vacuuming, walls and skirting boards, window cleaning, sinks and surfaces, oven and whitegoods, mopping, grout cleaning and mold treatment, picture rails, shelves and cupboards and garage cleaning.</p>

                            </div>
                            <div className="col-lg-6">
                                <h4>Offices</h4>
                                <p>Sanitising work spaces, dusting and vacuuming, dusting the electronics, staff rooms, bathrooms, reception area, meeting rooms and windows.</p>
                            </div>
                        </div>
                    </div>
            </section>

            <section id="testimony" className="home-section ">
                <div className="testimony-list ">
                    <div className="row equal">
                        <div className="col-lg-6 body">
                            <p>The Red Sea Cleaning Services team always carry out a high standard of service with cheer and good spirit.</p>
                            <p>Scoutt Winter</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="img-container">
                                <img className="card-img-top" src="/img/services/testimony.webp" alt="testimony" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="contact" className="section-contact">
                    <h2 className="section-heading">Contact us</h2>

                    <Row>
                        <Col md={4}>
                            <h5>
                            <i className="fa fa-phone"></i> Call</h5>
                            <p>
                                <a href="tel:0423969234">0423 969 234</a>
                            </p>
                        </Col>
                        <Col md={4}>
                            <h5>
                            <i className="fa fa-envelope"></i> Email us</h5>
                            <p>
                                <a href="mailto:belaige4@yahoo.com">
                                    belaige4@yahoo.com
                                </a>
                                </p>
                        </Col>
                        <Col  md={4}>
                            <h5>
                            <i className="fa fa-map-marker"></i> Our Address</h5>
                            <p>
                                
                                <a href="http://maps.google.com/?q=22+Jennings+Street,+New+Town,+TAS+7008" target="_blank">22 Jennings Street, New Town, TAS 7008</a>
                                </p>
                        </Col>
                    </Row>
            </section>

            <footer>
                <Container>
                            <small>&copy; Red Sea Cleaning Service. <br/>All rights reserved.</small>
                </Container>
            </footer>
        </Container>

    );
}

export default Screen;